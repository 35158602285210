import React, { Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/Main.scss";
// import "../styles/Main.css";
import { useTranslation } from "react-i18next";
import studysys from "../pages/Imgs/studysystem.png";
import DefaultHeader from "../components/DefaultHeader";
function StudySystemm() {
  const [t, i18n] = useTranslation();

  return (
    <Fragment>
      <DefaultHeader title={t("Study System")} backgroundImage={studysys} />
      <div className="BodyDiv">
        <Container className="MainPageBodyContainer">
          <div className="BodyTitle">
            <Row>
              <Col xs={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}></Col>
              <Col xs={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div style={{ color: "#09154E" }}>
                  <svg
                    width="50"
                    height="35"
                    viewBox="0 0 215 259"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3480_4927)">
                      <path
                        d="M124.566 163.976C124.158 162.903 122.956 162.362 121.882 162.77C120.809 163.177 120.268 164.38 120.675 165.453L154.662 254.839H62.3182L77.8801 213.907H121.716C122.864 213.907 123.796 212.975 123.796 211.827C123.796 210.679 122.864 209.747 121.716 209.747H79.4613L85.5363 193.766H110.718C111.867 193.766 112.799 192.834 112.799 191.686C112.799 190.537 111.867 189.605 110.718 189.605H87.1216L96.3048 165.453C96.7126 164.38 96.1716 163.177 95.0981 162.77C94.0246 162.362 92.8221 162.903 92.4143 163.976L57.3542 256.183C57.1129 256.824 57.1961 257.539 57.5872 258.105C57.9742 258.667 58.615 259.004 59.3015 259.004H157.679C158.361 259.004 159.002 258.667 159.393 258.105C159.78 257.543 159.867 256.824 159.626 256.183L124.566 163.976Z"
                        fill="#061041"
                      />
                      <path
                        d="M212.92 142.45H115.304C114.022 139.5 112.445 137.07 110.619 135.073C110.606 135.061 112.82 133.759 113.032 133.655C113.91 133.218 114.817 132.843 115.741 132.523C117.742 131.828 119.827 131.383 121.924 131.083C127.025 130.355 132.222 130.422 137.357 130.588C143.569 130.788 149.777 131.179 155.985 131.47C162.193 131.761 168.243 131.99 174.381 132.061C180.502 132.128 186.627 132.04 192.735 131.641C196.059 131.424 199.376 131.108 202.679 130.676C203.82 130.526 204.618 129.477 204.469 128.337C204.319 127.197 203.266 126.394 202.13 126.548C196.259 127.33 190.334 127.692 184.413 127.842C178.263 127.996 172.109 127.909 165.959 127.709C159.809 127.509 154.021 127.214 148.059 126.91C145.034 126.91 141.988 126.594 138.963 126.469C135.938 126.344 133.2 126.274 130.321 126.315C125.086 126.39 119.748 126.827 114.755 128.508C112.162 129.377 109.687 130.601 107.498 132.244C107.456 132.273 104.24 130.193 103.895 130.018C102.501 129.315 101.04 128.745 99.5505 128.283C96.4589 127.322 93.2384 126.823 90.0136 126.557C81.4879 125.858 72.9205 126.611 64.3989 127.039C55.8773 127.468 48.1296 127.826 39.9866 127.905C30.9366 127.988 21.8491 127.738 12.8698 126.548C11.7297 126.399 10.6853 127.197 10.5313 128.337C10.3774 129.477 11.1804 130.522 12.3205 130.676C28.4026 132.814 44.7676 132.165 60.9203 131.383C68.822 131 76.7486 130.393 84.6668 130.505C90.463 130.588 96.617 131.017 101.901 133.63C102.118 133.738 104.39 135.065 104.377 135.082C102.551 137.079 100.974 139.508 99.692 142.458H2.08047C0.932051 142.458 0 143.39 0 144.539C0 145.687 0.932051 146.619 2.08047 146.619H101.094C101.956 146.619 102.725 146.091 103.037 145.283C104.215 142.217 105.709 139.766 107.502 137.807C109.3 139.771 110.789 142.221 111.967 145.283C112.275 146.086 113.049 146.619 113.91 146.619H212.924C214.072 146.619 215.004 145.687 215.004 144.539C215.004 143.39 214.072 142.458 212.924 142.458L212.92 142.45Z"
                        fill="#061041"
                      />
                      <path
                        d="M84.24 114.387C84.9391 115.061 85.7796 115.535 86.6451 115.219C87.5646 114.878 87.9599 113.725 87.7685 112.76C87.5771 111.795 86.9571 110.988 86.358 110.21C84.2317 107.464 82.1554 104.676 80.129 101.855C74.5742 94.1165 69.2981 85.862 67.3175 76.5464C64.151 61.664 70.4923 45.2589 82.6797 36.1472C94.8671 27.0356 112.21 25.5336 125.908 32.1614C133.734 35.9475 140.367 42.2092 144.457 49.8855C148.543 57.5576 150.041 66.611 148.485 75.1651C146.754 84.6679 141.486 93.1222 135.902 101.006C133.734 104.069 131.504 107.089 129.211 110.06C128.379 111.142 127.489 112.394 127.718 113.738C127.888 114.741 129.033 115.406 129.998 115.227C131.101 115.019 131.7 113.754 132.332 112.927C134.321 110.33 136.273 107.709 138.182 105.055C144.291 96.563 150.099 87.5096 152.271 77.2787C155.584 61.7057 149.442 44.5849 137.196 34.4081C124.951 24.2313 107.196 21.294 92.2082 26.6611C81.8267 30.3807 72.8141 37.903 67.5047 47.568C62.1954 57.233 60.6933 68.97 63.6143 79.6003C65.7322 87.3098 70.0138 94.2289 74.4826 100.857C77.3578 105.117 80.3329 109.307 83.4078 113.426C83.6533 113.75 83.9321 114.091 84.2442 114.387H84.24Z"
                        fill="#061041"
                      />
                      <path
                        d="M108.489 51.2211C115.954 51.2211 122.025 57.2914 122.025 64.7555C122.025 65.9038 122.957 66.8358 124.105 66.8358C125.254 66.8358 126.186 65.9038 126.186 64.7555C126.186 54.9989 118.247 47.0605 108.489 47.0605C98.7321 47.0605 90.793 54.9989 90.793 64.7555C90.793 74.512 98.7321 82.4504 108.489 82.4504C109.638 82.4504 110.57 81.5184 110.57 80.3701C110.57 79.2218 109.638 78.2898 108.489 78.2898C101.025 78.2898 94.9539 72.2195 94.9539 64.7555C94.9539 57.2914 101.025 51.2211 108.489 51.2211Z"
                        fill="#061041"
                      />
                      <path
                        d="M139.834 18.5357C149.683 22.9126 158.625 33.78 160.631 43.8112C160.83 44.8014 161.696 45.4837 162.669 45.4837C162.807 45.4837 162.944 45.4713 163.081 45.4421C164.209 45.2175 164.937 44.1191 164.712 42.9957C162.428 31.5749 152.675 19.6882 141.528 14.7329C140.475 14.2669 139.247 14.7412 138.781 15.7897C138.315 16.8382 138.786 18.0697 139.838 18.5357H139.834Z"
                        fill="#061041"
                      />
                      <path
                        d="M145.824 4.07738C155.664 6.98978 176.248 29.8813 178.133 45.3544C178.262 46.4112 179.161 47.1851 180.197 47.1851C180.28 47.1851 180.368 47.1809 180.451 47.1685C181.591 47.0312 182.402 45.991 182.265 44.851C180.114 27.1852 158.09 3.36592 147.005 0.0873852C145.903 -0.23714 144.746 0.391107 144.421 1.49366C144.097 2.59621 144.725 3.75285 145.828 4.07738H145.824Z"
                        fill="#061041"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3480_4927">
                        <rect width="215" height="259" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  {t("Study System")}
                </div>
              </Col>
            </Row>
          </div>

          <Row>
            <Col className="BodyCol">
              <div className="BodyText">
                <ul style={{ listStyleType: "none" }}>
                  <li className="facultyDesign">{t("Study System")}</li>

                  <ul style={{ listStyleType: "decimal", marginRight: "2%" }}>
                    <p className="aboutuniP1" style={{ textAlign: "justify" }}>
                      <li>
                        {t(
                          "The University's study is based on the courses and hours approved. The annexes to these Regulations indicate the programmes of study, the distribution of its compulsory and optional courses at the different stages of study, and the number of credit hours per course, as well as the distribution of courses as a model between the four levels of study from Level I to Level IV, and a description of the content of each course."
                        )}
                      </li>
                      <li>
                        {t(
                          "Each university college shall determine the scientific content of the curriculum and shall issue a decision by the dean of the university in the light of the continuous updating of the curriculum in accordance with the new additions in the technological sciences. The scientific content of any course may be modified only after at least three academic years have been taught and the scientific content reviewed by specialized committees every five years."
                        )}
                      </li>
                      <li>
                        {t(
                          "A bachelor's degree student must study a number of courses equivalent to the number of credit hours specified in the rules of procedure for each college at the university."
                        )}
                      </li>
                      <li>
                        {t(
                          "At the beginning of each semester, the student selects the courses he will take during this semester from among the courses offered by the College, and is guided in this academic guide, in accordance with the rules on the registration of courses decided by the University."
                        )}
                      </li>
                      <li>
                        {t(
                          "A student is not considered to be registered until after payment of tuition fees."
                        )}
                      </li>
                    </p>
                  </ul>

                  <li className="facultyDesign">{t("Study Language")}</li>
                  <ul
                    style={{
                      listStyleType: "decimal",
                      marginRight: "2%",
                      textAlign: "justify",
                    }}
                  >
                    <p className="aboutuniP1">
                      <li>
                        {t(
                          "Studies at the University are conducted in English. The College Council may decide to teach some courses in any other language."
                        )}
                      </li>
                    </p>
                  </ul>

                  <li className="facultyDesign">{t("Course Load")}</li>

                  <ul
                    style={{
                      listStyleType: "decimal",
                      marginRight: "2%",
                      textAlign: "justify",
                    }}
                  >
                    <p className="aboutuniP1">
                      <li>
                        {t(
                          "Course credits limit the number of legal teaching hours allocated weekly to the instructor. In exceptional cases, in accordance with rules established by the College Council and at the suggestion of the Academic Student's Guide, the student must not exceed 19 hours and not fall below 6 hours. In all cases, the student's academic burden is determined by his/her cumulative rate and in coordination with the academic guide"
                        )}
                      </li>
                    </p>
                  </ul>
                  <li className="facultyDesign">{t("Classes")}</li>

                  <ul
                    style={{
                      listStyleType: "decimal",
                      marginRight: "2%",
                      textAlign: "justify",
                    }}
                  >
                    <p className="aboutuniP1">
                      <li>
                        {t(
                          "The university year begins in September each year, and the study lasts sixteen weeks for each of the two main semesters (first and second) and six weeks for the summer semester (third)."
                        )}
                      </li>
                      <li>
                        {t(
                          "In the public interest, the University Council may decide to start or complete the study before or after the said dates."
                        )}
                      </li>
                    </p>
                  </ul>
                  <li className="facultyDesign">
                    {t("Dates of Registration")}
                  </li>

                  <ul
                    style={{
                      listStyleType: "decimal",
                      marginRight: "2%",
                      textAlign: "justify",
                    }}
                  >
                    <p className="aboutuniP1">
                      <li>
                        {t(
                          "To complete the registration process, the student must meet all the necessary requirements for the course, including the successful completion of all qualifying courses."
                        )}
                      </li>
                      <li>
                        {t(
                          "The student selects the courses with the help of the academic guide and can then enroll."
                        )}
                      </li>
                      <li>
                        {t(
                          "The student may enroll during the first week of the start of the study, subject to the consent of the academic guide. The competent Dean of the College may also approve the student's registration before the end of the third week of study if the student has accepted the delay and the Academic Guide agrees that the student will pay a late registration fee determined by the University Council."
                        )}
                      </li>
                    </p>
                  </ul>
                  <li className="facultyDesign">
                    {t("Delete, add and withdraw")}
                  </li>

                  <ul
                    style={{
                      listStyleType: "decimal",
                      marginRight: "2%",
                      textAlign: "justify",
                    }}
                  >
                    <p className="aboutuniP1">
                      <li>
                        {t(
                          "After the consent of the academic guide and the professor of the subject, the student can add or delete one or more subjects within the first two weeks of the start of the study, taking into account the maximum and minimum approved hours."
                        )}
                      </li>
                      <li>
                        {t(
                          "The student may withdraw from enrollment in one or more courses after the approval of the academic guide and the professor of the subject within a period not exceeding the end of the fifth week of the start of the study, subject to the minimum number of credits. The student may withdraw from enrollment in one or more courses on the basis of an excuse accepted by the College Council after the fifth week of the start of the study."
                        )}
                      </li>
                      <li>
                        {t(
                          "In exceptional compelling reasons accepted by the College Council, the student may withdraw from all courses registered for the semester after the scheduled deadlines for the deletion of the subjects, but before the beginning of the end of the semester exam; these subjects are not included in the calculation of the student's score rates."
                        )}
                      </li>
                    </p>
                  </ul>

                  <li className="facultyDesign">{t("School Dropout")}</li>

                  <ul
                    style={{
                      listStyleType: "decimal",
                      marginRight: "2%",
                      textAlign: "justify",
                    }}
                  >
                    <p className="aboutuniP1">
                      <li>
                        {t(
                          "A student can drop out of their studies for a period not exceeding 3 semesters after submitting an acceptable written excuse after the approval of the College Council, and thereafter the student can complete their studies."
                        )}
                      </li>
                      <li>
                        {t(
                          "In the event of a student dropping out of school for more than three semesters with an acceptable excuse, the student can continue studying after. The previous decisions must count for him to succeed at least C +. Otherwise, the student must register the courses in which he received a lower rating than + C. In any case, the student will be subject to any new graduation requirements in the class in which he or she is re-enrolled."
                        )}
                      </li>
                      <li>
                        {t(
                          "A student shall be dismissed from college if he or she ceases to study without a written excuse for two semesters or if he or she ceases to study for the same period despite the rejection by the College Council of his or her excuse."
                        )}
                      </li>
                      <li>
                        {t(
                          "In case the student is interrupted for more than six semesters after presenting an excuse accepted by the College Council, he can return to study in college, provided that he is treated as a freshman student and does not count any points for the courses in which he has previously succeeded before his interruption. In any event, the student shall pay a suspension fee determined by the University Council."
                        )}
                      </li>
                    </p>
                  </ul>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}
export default StudySystemm;
