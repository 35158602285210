import React, { Fragment } from "react";
import { useEffect, useState } from "react";
import "../styles/Main.scss";
import { useTranslation } from "react-i18next";
import { Col, Row, Container } from "react-bootstrap";
import DefaultHeader from "../components/DefaultHeader";
import lMODELbG from "../pages/Imgs/LmodelBG.png";
import "../styles/componentsScss/SSU-Form.scss";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { BASE_URL } from "../components/apiConfig";
import { LazyLoadImage } from "react-lazy-load-image-component";

function CLCForm() {
  const [t, i18n] = useTranslation();
  const url = window.location.href.split("/");
  const [typeSelected, setTypeSelected] = useState(false);
  const [center, setCenter] = useState("");
  const [subTypeSelected, setSubTypeSelected] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [programs2, setPrograms2] = useState([]);
  const [programs3, setPrograms3] = useState([]);
  const [showSecondCard, setShowSecondCard] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [showCard2, setShowCard2] = useState(false);

  const [studentData, setStudentData] = useState({
    username: "",
    uniId: "",
    email: "",
    phone: "",
    subject: "",
    notes: "",
    college: "",
    Grade: "",
    type: "",
    company: "",
  });
  const [errors, setErrors] = useState({
    usernameErr: true,
    uniIdErr: true,
    emailErr: true,
    phoneErr: true,
    subjectErr: true,
    notesErr: true,
    collegeErr: true,
    typeErr: true,
    companyErr: true,
    gradeErr: true,
  });

  const StudentData = (e) => {
    if (e.target.name === "username") {
      // console.log(e.target.value)
      setStudentData({
        ...studentData,
        username: e.target.value,
      });

      if (e.target.value.length === 0) {
        setErrors({ ...errors, usernameErr: true });
      } else {
        setErrors({ ...errors, usernameErr: false });
      }
    }
    if (e.target.name === "company") {
      // console.log(e.target.value)
      setStudentData({
        ...studentData,
        company: e.target.value,
      });

      if (e.target.value.length === 0) {
        setErrors({ ...errors, companyErr: true });
      } else {
        setErrors({ ...errors, companyErr: false });
      }
    }
    if (e.target.name === "uniId") {
      const valId = /^[0-9\b]+$/;
      setStudentData({
        ...studentData,
        uniId: e.target.value,
      });
      if (
        e.target.value.length === 0 ||
        !valId.test(e.target.value) ||
        e.target.value.length < 6 ||
        e.target.value.length > 6
      ) {
        setErrors({ ...errors, uniIdErr: true });
      } else {
        setErrors({ ...errors, uniIdErr: false });
      }
    }
    if (e.target.name === "email") {
      setStudentData({
        ...studentData,
        email: e.target.value,
      });
      if (e.target.value.length === 0 || !/\S+@\S+\.\S+/.test(e.target.value)) {
        setErrors({ ...errors, emailErr: true });
      } else {
        setErrors({ ...errors, emailErr: false });
      }
    }
    if (e.target.name === "phone") {
      setStudentData({
        ...studentData,
        phone: e.target.value,
      });
      if (
        e.target.value.length === 0 ||
        e.target.value.length < 11 ||
        e.target.value.length > 11
      ) {
        setErrors({ ...errors, phoneErr: true });
      } else {
        setErrors({ ...errors, phoneErr: false });
      }
    }

    if (e.target.name === "notes") {
      setStudentData({
        ...studentData,
        notes: e.target.value,
      });
      if (
        e.target.value.length === 0 ||
        e.target.value.length < 10 ||
        e.target.value.length > 10
      ) {
        setErrors({ ...errors, notesErr: true });
      } else {
        setErrors({ ...errors, notesErr: false });
      }
    }
    if (e.target.name === "college") {
      setStudentData({
        ...studentData,
        college: e.target.value,
      });
      if (e.target.value.length === 0) {
        setErrors({ ...errors, collegeErr: true });
      } else {
        setErrors({ ...errors, collegeErr: false });
      }
    }
    if (e.target.name === "grade") {
      setStudentData({
        ...studentData,
        college: e.target.value,
      });
      if (e.target.value.length === 0) {
        setErrors({ ...errors, collegeErr: true });
      } else {
        setErrors({ ...errors, collegeErr: false });
      }
    }
    if (e.target.name === "type") {
      setStudentData({
        ...studentData,
        type: e.target.value,
      });
      if (e.target.value.length === 0) {
        setErrors({ ...errors, typeErr: true });
      } else {
        setErrors({ ...errors, typeErr: false });
      }
    }
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const urlPage = url[3];

  const handleClicking = () => {
    if (!showSecondCard) {
      axios
        .get(`${BASE_URL}/program/non-undergraduate`)
        .then((res) => {
          setPrograms(res.data.data);
          // console.log(res.data.data);
          setShowSecondCard(true);
        })
        .catch((error) => {
          // console.error('Error fetching programs:', error);
        });
    } else {
      setShowSecondCard(false);
    }
  };

  const handleClicking2 = () => {
    if (!showCard) {
      axios.get(`${BASE_URL}/program/undergraduate/it`).then((res) => {
        setPrograms2(res.data.data);
        // console.log(res.data.data)
        setShowCard(true);
      });
    } else {
      setShowCard(false);
    }
  };

  // const handleClicking3 = (_id) => {
  //     if (!showCard2) {
  //         axios.get(`${BASE_URL}/program/${_id}`)
  //             .then((res) => {
  //                 setPrograms3(res.data.data)
  //                 console.log(res.data.data)
  //             })
  //     } else {
  //         setShowCard2(false);
  //     }
  // }
  const handleClicking3 = (_id) => {
    setShowCard2(!showCard2);
    axios.get(`${BASE_URL}/program/${_id}`).then((res) => {
      setPrograms3(res.data.data);
      console.log(res.data.data);
    });
  };

  return (
    <Fragment>
      <DefaultHeader
        title={t("Continuing Learning Center")}
        backgroundImage={lMODELbG}
      />
      <div className="BodyDiv">
        <Container className="MainPageBodyContainer">
          <div className="BodyTitle">
            <Row>
              <Col xs={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <br />

                <div style={{ color: "rgb(17, 38, 113)" }}>
                  <svg
                    width="50"
                    height="35"
                    viewBox="0 0 215 259"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3480_4927)">
                      <path
                        d="M124.566 163.976C124.158 162.903 122.956 162.362 121.882 162.77C120.809 163.177 120.268 164.38 120.675 165.453L154.662 254.839H62.3182L77.8801 213.907H121.716C122.864 213.907 123.796 212.975 123.796 211.827C123.796 210.679 122.864 209.747 121.716 209.747H79.4613L85.5363 193.766H110.718C111.867 193.766 112.799 192.834 112.799 191.686C112.799 190.537 111.867 189.605 110.718 189.605H87.1216L96.3048 165.453C96.7126 164.38 96.1716 163.177 95.0981 162.77C94.0246 162.362 92.8221 162.903 92.4143 163.976L57.3542 256.183C57.1129 256.824 57.1961 257.539 57.5872 258.105C57.9742 258.667 58.615 259.004 59.3015 259.004H157.679C158.361 259.004 159.002 258.667 159.393 258.105C159.78 257.543 159.867 256.824 159.626 256.183L124.566 163.976Z"
                        fill="#061041"
                      />
                      <path
                        d="M212.92 142.45H115.304C114.022 139.5 112.445 137.07 110.619 135.073C110.606 135.061 112.82 133.759 113.032 133.655C113.91 133.218 114.817 132.843 115.741 132.523C117.742 131.828 119.827 131.383 121.924 131.083C127.025 130.355 132.222 130.422 137.357 130.588C143.569 130.788 149.777 131.179 155.985 131.47C162.193 131.761 168.243 131.99 174.381 132.061C180.502 132.128 186.627 132.04 192.735 131.641C196.059 131.424 199.376 131.108 202.679 130.676C203.82 130.526 204.618 129.477 204.469 128.337C204.319 127.197 203.266 126.394 202.13 126.548C196.259 127.33 190.334 127.692 184.413 127.842C178.263 127.996 172.109 127.909 165.959 127.709C159.809 127.509 154.021 127.214 148.059 126.91C145.034 126.91 141.988 126.594 138.963 126.469C135.938 126.344 133.2 126.274 130.321 126.315C125.086 126.39 119.748 126.827 114.755 128.508C112.162 129.377 109.687 130.601 107.498 132.244C107.456 132.273 104.24 130.193 103.895 130.018C102.501 129.315 101.04 128.745 99.5505 128.283C96.4589 127.322 93.2384 126.823 90.0136 126.557C81.4879 125.858 72.9205 126.611 64.3989 127.039C55.8773 127.468 48.1296 127.826 39.9866 127.905C30.9366 127.988 21.8491 127.738 12.8698 126.548C11.7297 126.399 10.6853 127.197 10.5313 128.337C10.3774 129.477 11.1804 130.522 12.3205 130.676C28.4026 132.814 44.7676 132.165 60.9203 131.383C68.822 131 76.7486 130.393 84.6668 130.505C90.463 130.588 96.617 131.017 101.901 133.63C102.118 133.738 104.39 135.065 104.377 135.082C102.551 137.079 100.974 139.508 99.692 142.458H2.08047C0.932051 142.458 0 143.39 0 144.539C0 145.687 0.932051 146.619 2.08047 146.619H101.094C101.956 146.619 102.725 146.091 103.037 145.283C104.215 142.217 105.709 139.766 107.502 137.807C109.3 139.771 110.789 142.221 111.967 145.283C112.275 146.086 113.049 146.619 113.91 146.619H212.924C214.072 146.619 215.004 145.687 215.004 144.539C215.004 143.39 214.072 142.458 212.924 142.458L212.92 142.45Z"
                        fill="#061041"
                      />
                      <path
                        d="M84.24 114.387C84.9391 115.061 85.7796 115.535 86.6451 115.219C87.5646 114.878 87.9599 113.725 87.7685 112.76C87.5771 111.795 86.9571 110.988 86.358 110.21C84.2317 107.464 82.1554 104.676 80.129 101.855C74.5742 94.1165 69.2981 85.862 67.3175 76.5464C64.151 61.664 70.4923 45.2589 82.6797 36.1472C94.8671 27.0356 112.21 25.5336 125.908 32.1614C133.734 35.9475 140.367 42.2092 144.457 49.8855C148.543 57.5576 150.041 66.611 148.485 75.1651C146.754 84.6679 141.486 93.1222 135.902 101.006C133.734 104.069 131.504 107.089 129.211 110.06C128.379 111.142 127.489 112.394 127.718 113.738C127.888 114.741 129.033 115.406 129.998 115.227C131.101 115.019 131.7 113.754 132.332 112.927C134.321 110.33 136.273 107.709 138.182 105.055C144.291 96.563 150.099 87.5096 152.271 77.2787C155.584 61.7057 149.442 44.5849 137.196 34.4081C124.951 24.2313 107.196 21.294 92.2082 26.6611C81.8267 30.3807 72.8141 37.903 67.5047 47.568C62.1954 57.233 60.6933 68.97 63.6143 79.6003C65.7322 87.3098 70.0138 94.2289 74.4826 100.857C77.3578 105.117 80.3329 109.307 83.4078 113.426C83.6533 113.75 83.9321 114.091 84.2442 114.387H84.24Z"
                        fill="#061041"
                      />
                      <path
                        d="M108.489 51.2211C115.954 51.2211 122.025 57.2914 122.025 64.7555C122.025 65.9038 122.957 66.8358 124.105 66.8358C125.254 66.8358 126.186 65.9038 126.186 64.7555C126.186 54.9989 118.247 47.0605 108.489 47.0605C98.7321 47.0605 90.793 54.9989 90.793 64.7555C90.793 74.512 98.7321 82.4504 108.489 82.4504C109.638 82.4504 110.57 81.5184 110.57 80.3701C110.57 79.2218 109.638 78.2898 108.489 78.2898C101.025 78.2898 94.9539 72.2195 94.9539 64.7555C94.9539 57.2914 101.025 51.2211 108.489 51.2211Z"
                        fill="#061041"
                      />
                      <path
                        d="M139.834 18.5357C149.683 22.9126 158.625 33.78 160.631 43.8112C160.83 44.8014 161.696 45.4837 162.669 45.4837C162.807 45.4837 162.944 45.4713 163.081 45.4421C164.209 45.2175 164.937 44.1191 164.712 42.9957C162.428 31.5749 152.675 19.6882 141.528 14.7329C140.475 14.2669 139.247 14.7412 138.781 15.7897C138.315 16.8382 138.786 18.0697 139.838 18.5357H139.834Z"
                        fill="#061041"
                      />
                      <path
                        d="M145.824 4.07738C155.664 6.98978 176.248 29.8813 178.133 45.3544C178.262 46.4112 179.161 47.1851 180.197 47.1851C180.28 47.1851 180.368 47.1809 180.451 47.1685C181.591 47.0312 182.402 45.991 182.265 44.851C180.114 27.1852 158.09 3.36592 147.005 0.0873852C145.903 -0.23714 144.746 0.391107 144.421 1.49366C144.097 2.59621 144.725 3.75285 145.828 4.07738H145.824Z"
                        fill="#061041"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3480_4927">
                        <rect width="215" height="259" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {t("Continuing Learning Center")}
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col
              className="BodyCol"
              xs={{ span: 12, order: 2 }}
              md={{ span: 6, order: 1 }}
              lg={{ span: 6, order: 1 }}
            >
              <div className="div">
                <br></br>
                <div className="text-wrapper">
                  <LazyLoadImage
                    alt="Heroicons square"
                    src="https://generation-sessions.s3.amazonaws.com/9041e72c92a90c0459a94bbe0336a1c0/img/heroicons-square-3-stack-3d@2x.png"
                  />
                  <span
                    style={{
                      fontSize: "30px",
                      padding: "20px",
                      fontWeight: "bold",
                      color: "rgb(17, 38, 113)",
                    }}
                  >
                    {" "}
                    {t("Course Type")}
                  </span>
                </div>
              </div>
              <br></br>

              <div className="row row-cols-1 row-cols-sm-3 g-5">
                <div className="col1">
                  <div
                    className="card h-100"
                    style={{
                      borderRadius: "15px",
                      borderBottom: "5px solid #E68900",
                      cursor: "pointer",
                    }}
                    onClick={handleClicking}
                  >
                    <div className="card-body">
                      <div className="group_form">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <br></br>

                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "20px",
                              color: "#142B7B",
                            }}
                          >
                            {t("Training Programs")}
                          </div>

                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.56995 5.93005L3.49995 12.0001L9.56995 18.0701"
                              stroke="#157F40"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M20.5 12H3.67"
                              stroke="#157F40"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col1">
                  <div
                    className="card h-100"
                    style={{
                      borderRadius: "15px",
                      borderBottom: "5px solid #E68900",
                      cursor: "pointer",
                    }}
                    onClick={handleClicking2}
                  >
                    <div className="card-body">
                      <div className="group_form">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <br></br>

                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "20px",
                              color: "#142B7B",
                            }}
                          >
                            {t("Student Training Programs")}
                          </div>

                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.56995 5.93005L3.49995 12.0001L9.56995 18.0701"
                              stroke="#157F40"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M20.5 12H3.67"
                              stroke="#157F40"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {showSecondCard && (
                <>
                  <div className="div">
                    <br></br>
                    <br></br>
                    <div className="text-wrapper">
                      <LazyLoadImage
                        alt="Heroicons square"
                        src="https://generation-sessions.s3.amazonaws.com/9041e72c92a90c0459a94bbe0336a1c0/img/heroicons-square-3-stack-3d@2x.png"
                      />
                      <span
                        style={{
                          fontSize: "30px",
                          padding: "20px",
                          fontWeight: "bold",
                          color: "rgb(17, 38, 113)",
                        }}
                      >
                        {" "}
                        {t("Course Track")}{" "}
                      </span>
                    </div>
                  </div>
                  <br></br>
                  <div className="row row-cols-1 row-cols-sm-3 g-5">
                    {programs.map((pro) => (
                      <div className="col1">
                        <div
                          className="card h-100"
                          onClick={handleClicking3}
                          style={{
                            borderRadius: "15px",
                            borderBottom: "5px solid #E68900",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            className="card-body"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div className="group_form">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#142B7B",
                                  }}
                                >
                                  {pro.programName}
                                </div>

                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.56995 5.93005L3.49995 12.0001L9.56995 18.0701"
                                    stroke="#157F40"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M20.5 12H3.67"
                                    stroke="#157F40"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}

              {showCard && (
                <>
                  <div className="div">
                    <br></br>
                    <br></br>
                    <div className="text-wrapper">
                      <LazyLoadImage
                        alt="Heroicons square"
                        src="https://generation-sessions.s3.amazonaws.com/9041e72c92a90c0459a94bbe0336a1c0/img/heroicons-square-3-stack-3d@2x.png"
                      />
                      <span
                        style={{
                          fontSize: "30px",
                          padding: "20px",
                          fontWeight: "bold",
                          color: "rgb(17, 38, 113)",
                        }}
                      >
                        {" "}
                        {t("Course Track")}{" "}
                      </span>
                    </div>
                  </div>
                  <br></br>
                  <div className="row row-cols-1 row-cols-sm-3 g-5">
                    {programs2.map((pro2, index, _id) => (
                      <div className="col1">
                        <div
                          className="card h-100"
                          key={index}
                          onClick={() => handleClicking3(pro2._id)}
                          style={{
                            borderRadius: "15px",
                            borderBottom: "5px solid #E68900",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            className="card-body"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div className="group_form">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#142B7B",
                                  }}
                                >
                                  {pro2.programName}
                                </div>

                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.56995 5.93005L3.49995 12.0001L9.56995 18.0701"
                                    stroke="#157F40"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M20.5 12H3.67"
                                    stroke="#157F40"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {showCard2 && (
                <>
                  <div className="div">
                    <br></br>
                    <br></br>
                    <div className="text-wrapper">
                      <LazyLoadImage
                        alt="Heroicons square"
                        src="https://generation-sessions.s3.amazonaws.com/9041e72c92a90c0459a94bbe0336a1c0/img/heroicons-square-3-stack-3d@2x.png"
                      />
                      <span
                        style={{
                          fontSize: "30px",
                          padding: "20px",
                          fontWeight: "bold",
                          color: "rgb(17, 38, 113)",
                        }}
                      >
                        {" "}
                        {t("Interested Course 1:")}{" "}
                      </span>
                    </div>
                  </div>
                  <br></br>
                  <div className="row row-cols-1 row-cols-sm-3 g-5">
                    {programs3.map((pro3) => (
                      <div className="col1">
                        <div
                          className="card h-100"
                          key={pro3._id}
                          style={{
                            borderRadius: "15px",
                            borderBottom: "5px solid #E68900",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            className="card-body"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div className="group_form">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#142B7B",
                                  }}
                                >
                                  {pro3.courseName}
                                </div>

                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.56995 5.93005L3.49995 12.0001L9.56995 18.0701"
                                    stroke="#157F40"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M20.5 12H3.67"
                                    stroke="#157F40"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              <br></br>

              <br></br>

              <br></br>

              <div className="text-wrapper">
                <LazyLoadImage
                  alt="Heroicons square"
                  src="https://generation-sessions.s3.amazonaws.com/9041e72c92a90c0459a94bbe0336a1c0/img/heroicons-square-3-stack-3d@2x.png"
                />
                <span
                  style={{
                    fontSize: "30px",
                    padding: "20px",
                    fontWeight: "bold",
                    color: "rgb(17, 38, 113)",
                  }}
                >
                  {" "}
                  {t("Additional Information")}{" "}
                </span>
              </div>

              <form className="row g-3" name="caseForm">
                <label className="form-check-label" for="defaultCheck1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                    style={{ marginLeft: "2%" }}
                    onChange={handleCheckboxChange}
                  />
                  {t("EELU Member")}
                </label>
                {isChecked && (
                  <>
                    <label
                      for="subject2"
                      className="form-label"
                      style={{ color: "rgb(17 38 113)" }}
                    >
                      {" "}
                      {t("Your ID")}{" "}
                    </label>
                    <input
                      type="text"
                      id="subject2"
                      name="uniId"
                      onChange={(e) => StudentData(e)}
                      className={`form-control ${
                        errors.uniIdErr ? "border-primary" : "border-primary"
                      }`}
                      placeholder={t("Enter Your University ID")}
                      value={studentData.uniId}
                      style={{
                        width: "80%",
                        height: "45px",
                        borderColor: "rgb(20, 43, 123)",
                      }}
                    ></input>
                    <p className="text-danger">
                      {errors.uniIdErr ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          style={{ width: "3%", marginLeft: "5px" }}
                          fill="currentColor"
                          className="bi bi-info-circle"
                          viewBox="0 0 16 16"
                        >
                          {" "}
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />{" "}
                        </svg>
                      ) : (
                        " "
                      )}
                      {errors.uniIdErr
                        ? t("University ID Must be Entered Correctly")
                        : ""}{" "}
                    </p>
                  </>
                )}

                <label
                  for="subject2"
                  className="form-label"
                  style={{ color: "rgb(17 38 113)" }}
                >
                  {" "}
                  {t("Contact Name")}
                </label>
                <input
                  type="text"
                  id="subject2"
                  name="username"
                  onChange={(e) => StudentData(e)}
                  className={`form-control ${
                    errors.usernameErr ? "border-primary" : "border-primary"
                  }`}
                  placeholder={t("Enter Your Name Correct")}
                  value={studentData.username}
                  style={{
                    width: "80%",
                    height: "45px",
                    borderColor: "rgb(20, 43, 123)",
                  }}
                ></input>
                <p className="text-danger">
                  {errors.usernameErr ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      style={{ width: "3%", marginLeft: "5px" }}
                      fill="currentColor"
                      className="bi bi-info-circle"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />{" "}
                    </svg>
                  ) : (
                    " "
                  )}
                  {errors.usernameErr ? t("This Field is Required") : ""}{" "}
                </p>

                <label
                  for="subject2"
                  className="form-label"
                  style={{ color: "rgb(17 38 113)" }}
                >
                  {" "}
                  {t("Student University Email")}{" "}
                </label>
                <input
                  type="text"
                  id="subject2"
                  name="email"
                  onChange={(e) => StudentData(e)}
                  className={`form-control ${
                    errors.emailErr ? "border-primary" : "border-primary"
                  }`}
                  placeholder={t("Enter Your University Email")}
                  value={studentData.email}
                  style={{
                    width: "80%",
                    height: "45px",
                    borderColor: "rgb(20, 43, 123)",
                  }}
                ></input>
                <p className="text-danger">
                  {errors.usernameErr ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      style={{ width: "3%", marginLeft: "5px" }}
                      fill="currentColor"
                      className="bi bi-info-circle"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />{" "}
                    </svg>
                  ) : (
                    " "
                  )}
                  {errors.emailErr
                    ? t("The E-mail Should be Correctly Written")
                    : ""}{" "}
                </p>
                <label
                  for="subject2"
                  className="form-label"
                  style={{ color: "rgb(17 38 113)" }}
                >
                  {" "}
                  {t("What'sapp Number")}{" "}
                </label>
                <input
                  type="text"
                  id="subject2"
                  name="phone"
                  onChange={(e) => StudentData(e)}
                  className={`form-control ${
                    errors.phoneErr ? "border-primary" : "border-primary"
                  }`}
                  placeholder={t("Enter Your Egyption Phone")}
                  value={studentData.phone}
                  style={{
                    width: "80%",
                    height: "45px",
                    borderColor: "rgb(20, 43, 123)",
                  }}
                ></input>

                <p className="text-danger">
                  {errors.phoneErr ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      style={{ width: "3%", marginLeft: "5px" }}
                      fill="currentColor"
                      className="bi bi-info-circle"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />{" "}
                    </svg>
                  ) : (
                    " "
                  )}
                  {errors.phoneErr
                    ? t(
                        "You Must Enter an Egyptian Telephone Number with 11 Valid Numbers"
                      )
                    : ""}{" "}
                </p>

                <label className="form-label">
                  {" "}
                  {t("Center/City")}
                  <select
                    className="form-select form-select-sm"
                    aria-label=".form-select-sm example"
                    value={center}
                    onChange={(e) => setCenter(e.target.value)}
                    style={{
                      width: "80%",
                      marginTop: "20px",
                      borderColor: "rgb(20, 43, 123)",
                      height: "45px",
                      fontSize: "20px",
                    }}
                  >
                    <option value="Doki" selected>
                      الدقي
                    </option>
                    <option value="Alexandria">الاسكندرية</option>
                    <option value="Alfayoum">الفيوم</option>
                    <option value="Alsadat">السادات</option>
                    <option value="Assuit">أسيوط</option>
                    <option value="Aswan">أسوان</option>
                    <option value="Beni Suef">بني سويف</option>
                    <option value="Hurghada">الغردقة</option>
                    <option value="Ismailia">الاسماعيلية</option>
                    <option value="Menoufia">المنوفية</option>
                    <option value="Qena">قنا</option>
                    <option value="Tanta">طنطا</option>
                    <option value="Sohag">سوهاج</option>
                  </select>
                </label>
                {isChecked && (
                  <>
                    <label
                      className="form-label"
                      style={{ color: "rgb(17 38 113)" }}
                    >
                      {" "}
                      {t("Your Faculty")}
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        name="college"
                        value={studentData.college}
                        onChange={(e) => StudentData(e)}
                        style={{
                          width: "80%",
                          marginTop: "20px",
                          borderColor: "rgb(20, 43, 123)",
                          height: "45px",
                          fontSize: "20px",
                        }}
                      >
                        <option value=""> {t("Choose College")} </option>
                        <option value="الدراسات العليا والدبلومات">
                          {" "}
                          {t(
                            "Faculty of Computers and\n\n Information Technology"
                          )}{" "}
                        </option>
                        <option value="Faculty of Commercial Studies and Business Admin">
                          {t(
                            "EELU Data.EELU Main Pages.Faculty of Commercial Studies and Business Administration.title"
                          )}
                        </option>
                        <option value="الدراسات العليا والدبلومات">
                          {" "}
                          أخري{" "}
                        </option>
                      </select>
                    </label>
                    <p className="text-danger">
                      {errors.collegeErr ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-info-circle"
                          viewBox="0 0 16 16"
                        >
                          {" "}
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />{" "}
                        </svg>
                      ) : (
                        " "
                      )}
                      {errors.collegeErr
                        ? t(
                            "You Must Enter The College Selection to Which you are Bound"
                          )
                        : ""}{" "}
                    </p>

                    <label
                      className="form-label"
                      style={{ color: "rgb(17 38 113)" }}
                    >
                      {" "}
                      {t("Choose Grade")}
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        name="grade"
                        value={studentData.Grade}
                        onChange={(e) => StudentData(e)}
                        style={{
                          width: "80%",
                          marginTop: "20px",
                          borderColor: "rgb(20, 43, 123)",
                          height: "45px",
                          fontSize: "20px",
                        }}
                      >
                        <option value=""> {t("Choose Grade")} </option>
                        <option value="Faculty of Commercial Studies and Business Admin">
                          {" "}
                          1{" "}
                        </option>
                        <option value="الدراسات العليا والدبلومات">2 </option>
                        <option value="الدراسات العليا والدبلومات"> 3 </option>
                        <option value="الدراسات العليا والدبلومات"> 4 </option>
                        <option value="الدراسات العليا والدبلومات">
                          {" "}
                          {t("Graduated")}{" "}
                        </option>
                      </select>
                    </label>
                    {/* <p className="text-danger">
                                                {errors.gradeErr ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" /> </svg> : ' '}

                                                {errors.gradeErr ? t('You Must Enter The College Selection to Which you are Bound') : ""} </p> */}
                  </>
                )}
                <label
                  for="subject2"
                  className="form-label"
                  style={{ color: "rgb(17 38 113)" }}
                >
                  {" "}
                  {t("Notes")}
                </label>
                <textarea
                  id="subject2"
                  name="notes"
                  value={studentData.notes}
                  onChange={(e) => StudentData(e)}
                  className={`form-control ${
                    errors.subjectErr ? "border-primary" : "border-primary"
                  }`}
                  placeholder={t("Enter the Topic Description")}
                  style={{
                    width: "80%",
                    height: "45px",
                    borderColor: "rgb(20, 43, 123)",
                  }}
                ></textarea>
                <p className="text-danger">
                  {errors.notesErr ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      style={{ width: "3%", marginLeft: "5px" }}
                      fill="currentColor"
                      className="bi bi-info-circle"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />{" "}
                    </svg>
                  ) : (
                    " "
                  )}
                  {errors.notesErr
                    ? t("Description Must be Entered Correctly")
                    : ""}{" "}
                </p>

                <label
                  for="subject2"
                  className="form-label"
                  style={{ color: "rgb(17 38 113)" }}
                >
                  {" "}
                  {t("Company Name")}
                </label>
                <input
                  type="text"
                  id="subject2"
                  name="company"
                  onChange={(e) => StudentData(e)}
                  className={`form-control ${
                    errors.companyErr ? "border-primary" : "border-primary"
                  }`}
                  placeholder={t("Enter Your Company Name")}
                  value={studentData.company}
                  style={{
                    width: "80%",
                    height: "45px",
                    borderColor: "rgb(20, 43, 123)",
                  }}
                ></input>
                <p className="text-danger">
                  {errors.companyErr ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      style={{ width: "3%", marginLeft: "5px" }}
                      fill="currentColor"
                      className="bi bi-info-circle"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />{" "}
                    </svg>
                  ) : (
                    " "
                  )}
                  {errors.companyErr ? t("This Field is Required") : ""}{" "}
                </p>

                <label
                  for="subject2"
                  className="form-label"
                  style={{ color: "rgb(17 38 113)" }}
                >
                  {" "}
                  {t("Payment")}
                </label>
                <p
                  style={{
                    fontSize: "20px",
                    marginTop: "-2px",
                    color: "#142B7B",
                  }}
                >
                  {t(
                    "Please be aware that once you have registered you will receive an instant payment code on the phone number you registered and valid code 3 days immediately after registration. The request will be rejected automatically in case of non-payment immediately after the expiration of three days"
                  )}
                </p>
                <label
                  for="subject2"
                  className="form-label"
                  style={{ color: "rgb(17 38 113)", marginTop: "-1%" }}
                >
                  {" "}
                  {t("Cancellation Policy")}
                </label>

                <ul style={{ listStyleType: "decimal", marginRight: "2%" }}>
                  <p
                    style={{
                      fontSize: "20px",
                      color: "#142B7B",
                      marginTop: "-2%",
                    }}
                  >
                    <li>
                      {t(
                        "Only One week before the beginning of the course, the applicant can be totally refunded."
                      )}
                    </li>
                    <li>
                      {t(
                        "Before the first day of the course 50% will be deducted for registration process"
                      )}
                    </li>
                    <li>{t("Other than that there is no refund")}</li>
                    <li>
                      {t(
                        "This registration form will be cancelled if the program fees were not submitted before the deadline."
                      )}
                    </li>
                  </p>
                </ul>

                <label
                  for="subject2"
                  className="form-label"
                  style={{ color: "rgb(17 38 113)", marginTop: "-1%" }}
                >
                  {" "}
                  {t("I have read & Agreed for the Cancelation Policy")}
                </label>
                <label
                  className="form-check-label"
                  for="defaultCheck1"
                  style={{ marginTop: "-1%" }}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                    style={{ marginLeft: "2%" }}
                  />
                  {t("Yes")}
                </label>

                <br></br>
                <br></br>
                <br></br>

                <div className="#thanksalert">
                  <button
                    type="submit"
                    className="btn btn-info"
                    style={{
                      backgroundColor: " rgb(17 35 97)",
                      justifyContent: "center",
                      padding: " 8px 24px",
                      borderRadius: "8px",
                      color: " #f4f5fc",
                      fontSize: "20px",
                      border: "0px",
                    }}
                    disabled={
                      !errors.usernameErr &&
                      !errors.uniIdErr &&
                      !errors.phoneErr &&
                      !errors.emailErr &&
                      !errors.sub &&
                      !errors.collegeErr &&
                      !errors.typeErr &&
                      typeSelected &&
                      subTypeSelected
                        ? false
                        : true
                    }
                    // disabled={}
                  >
                    {t("Submit")}
                  </button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}
export default CLCForm;
