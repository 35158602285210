import React, { useState, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function RecordMeeting() {
  const [t, i18n] = useTranslation();

  const [formData, setFormData] = useState({
    advisorEmail: "",
    studentId: "",
    subject: "",
    description: "",
    meetingRecord: "",
    recordType: "012R50000016MoT",
    type: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = document.createElement("form");
    form.action =
      "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8";
    form.method = "POST";

    const fields = {
      orgid: "00D4L000000i6vU",
      recordType: "012R50000016MoT",
      retURL: "https://www.eelu.edu.eg/",
      "00NR5000002AAL7": formData.advisorEmail,
      "00N8e00000848tp": formData.studentId,
      subject: formData.subject,
      description: formData.description,
      "00NR5000002AAOL": formData.meetingRecord,
      //   recordType: formData.recordType,
      type: formData.type,
    };

    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = fields[key];
        form.appendChild(input);
      }
    }

    document.body.appendChild(form);
    form.submit();
  };
  return (
    <Fragment>
      <Container>
        <h3 style={{ marginTop: "2%", color: "rgb(17, 38, 113)" }}>
          تسجيل اجتماع مع الطلبة
        </h3>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} md={6}>
              <label htmlFor="advisorEmail">البريد الالكتروني للمعيد </label>
              <input
                id="advisorEmail"
                name="advisorEmail"
                type="email"
                className="form-control"
                value={formData.advisorEmail}
                onChange={handleChange}
                style={{ height: "35%" }}
              />
            </Col>
            <Col xs={12} md={6}>
              <label htmlFor="studentId">الرقم الجامعي</label>
              <textarea
                id="studentId"
                name="studentId"
                className="form-control"
                value={formData.studentId}
                onChange={handleChange}
                style={{ height: "35%" }}
              ></textarea>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <label htmlFor="subject">الموضوع</label>
              <input
                id="subject"
                name="subject"
                type="text"
                className="form-control"
                value={formData.subject}
                onChange={handleChange}
                style={{ height: "35%" }}
              />
            </Col>
            <Col xs={12} md={6}>
              <label htmlFor="description">الوصف</label>
              <textarea
                id="description"
                name="description"
                className="form-control"
                value={formData.description}
                onChange={handleChange}
                style={{ height: "35%" }}
              ></textarea>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <label htmlFor="meetingRecord"> تسجيل الاجتماع</label>
              <textarea
                id="meetingRecord"
                name="meetingRecord"
                className="form-control"
                value={formData.meetingRecord}
                onChange={handleChange}
                style={{ height: "35%" }}
              ></textarea>
            </Col>
            <Col xs={12} md={6}>
              <label htmlFor="type">النوع</label>
              <select
                id="type"
                name="type"
                className="form-control"
                value={formData.type}
                onChange={handleChange}
                style={{ height: "35%" }}
              >
                <option value="">--None--</option>
                <option value="Request">Request</option>
                <option value="Complaint">Complaint</option>
                <option value="Question">Question</option>
                <option value="Suggestions">Suggestions</option>
              </select>
            </Col>
            {/* <Col xs={12} md={6}>
              <label htmlFor="recordType">Case Record Type</label>
              <select
                id="recordType"
                name="recordType"
                className="form-control"
                value={formData.recordType}
                onChange={handleChange}
              >
                <option value="012R50000016MoT">Appointment</option>
              </select>
            </Col> */}
          </Row>
          <Row>
            <Col xs={12}>
              <button
                type="submit"
                className="btn btn-info"
                style={{
                  backgroundColor: "rgb(17, 35, 97)",
                  color: "#f4f5fc",
                  marginTop: "2%",
                }}
              >
                {t("Submit")}
              </button>
            </Col>
          </Row>
        </form>
        <br></br>
        <br></br>
        <br></br>
      </Container>
    </Fragment>
  );
}
export default RecordMeeting;
